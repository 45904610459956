import { computed, Injectable, signal } from "@angular/core";
import { PolicyValidationResult, Validation } from "../models/validation";
import { PolicyTransaction } from "../models/policy";

@Injectable({
  providedIn: "root",
})
export class PolicyValidationStoreService {
  #policyValidationResults = signal<PolicyValidationResult[]>([]);

  // policy validation signal factory
  policyValidations = (transaction?: PolicyTransaction) =>
    computed(() => this.getPolicyValidations(transaction));

  // get validations for a single policy transaction
  getPolicyValidations(transaction?: PolicyTransaction): Validation[] {
    if (!transaction) {
      return [];
    }

    const result =
      this.#policyValidationResults().find(
        (result) => result.policyTransactionId === transaction.id,
      )?.validations ?? [];

    return result;
  }

  // set validations for multiple policy transactions
  setPolicyValidationResults(results: PolicyValidationResult[]) {
    const transactionsIds = results.map((result) => result.policyTransactionId);
    this.#policyValidationResults.update((original) => {
      // remove results associated to provided policy transactions
      const filtered = original.filter(
        (result) => !transactionsIds.includes(result.policyTransactionId),
      );
      // combine remaining results with provided results
      return [...filtered, ...results];
    });
  }
}
