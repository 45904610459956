import { Component, HostBinding, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "cq-icon-svg",
  imports: [MatIconModule],
  templateUrl: "./icon-svg.component.html",
  styleUrls: ["./icon-svg.component.scss"],
})
export class IconSvgComponent {
  @Input({ required: true }) name!: string;

  @HostBinding("class.cq-icon") readonly baseClass = true;
}
