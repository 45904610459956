import { Injectable } from "@angular/core";
import { ConfigService } from "@cq/app/core/environment/services/config.service";
import { EnvironmentService } from "@cq/app/core/environment/services/environment.service";
import { LogLevel, StsConfigHttpLoader } from "angular-auth-oidc-client";
import { map } from "rxjs";

const INTERNAL_CUSTOM_PARAMS = {
  auth_method: "ping-racf",
  realm: "employee",
};

const EXTERNAL_CUSTOM_PARAMS = {
  auth_method: "ping-racf-ia",
  realm: "distribution-partner",
};

@Injectable()
export class AuthConfigService {
  constructor(
    private readonly config: ConfigService,
    private readonly environment: EnvironmentService,
  ) {}

  createConfigLoader() {
    const config$ = this.config.app$.pipe(
      map((config) => ({
        authority: `https://${config.apigeeHost}/security-processing/enterprise-user-auth/v2/authorize`,
        redirectUrl: `${window.location.origin}/authorize/`,
        postLogoutRedirectUri: window.location.origin,
        clientId: config.appClientKey,
        scope: "openid",
        renewTimeBeforeTokenExpiresInSeconds: 30,
        responseType: "id_token token",
        silentRenewUrl: `${window.location.origin}/authorize/`,
        silentRenew: true,
        logLevel: LogLevel.Warn,
        customParamsAuthRequest: this.environment.isExternal
          ? EXTERNAL_CUSTOM_PARAMS
          : INTERNAL_CUSTOM_PARAMS,
        unauthorizedRoute: "/unauthorized",
        forbiddenRoute: "/unauthorized",
        checkRedirectUrlWhenCheckingIfIsCallback: false, // required to support our redirect url / implicit authorization pattern
      })),
    );
    return new StsConfigHttpLoader(config$);
  }
}
