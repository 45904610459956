import { animate, style, transition, trigger } from "@angular/animations";
import { A11yModule } from "@angular/cdk/a11y";
import { NgComponentOutlet } from "@angular/common";
import { Component, computed, inject } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { PROGRESS_EVENTS } from "../../services/progress.service";

@Component({
  selector: "cq-progress-overlay",
  imports: [A11yModule, MatProgressBarModule, NgComponentOutlet],
  templateUrl: "./progress-overlay.component.html",
  styleUrl: "./progress-overlay.component.scss",
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProgressOverlayComponent {
  private events = inject(PROGRESS_EVENTS);
  private componentEvent = computed(() => {
    return this.events().find(
      (event) => event.options?.component !== undefined,
    );
  });
  hasComponent = computed(() => this.componentEvent() !== undefined);
  component = computed(() => this.componentEvent()?.options?.component ?? null);
  injector = computed(() => this.componentEvent()?.options?.injector);
}
