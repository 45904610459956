import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "cq-dialog-action-spacer",
  imports: [CommonModule],
  template: "",
  styleUrls: ["./dialog-action-spacer.component.scss"],
})
export class DialogActionSpacerComponent {}
