<div class="auth-container">
  <div class="header">
    <img src="assets/images/nationwide-white-logo.svg" alt="Nationwide" />
  </div>
  <div class="body">
    <div class="circle">
      <cq-icon-font class="message-icon" name="warning"></cq-icon-font>
    </div>
    <div class="message">Authentication Failed</div>
    <div class="description">
      Please return to your previous application and relaunch ClearQuote to try
      again or contact the Help Desk at {{ helpDeskNumber() }}.
    </div>
  </div>
  <footer>
    <span class="brand">
      ClearQuote <span class="service-mark">&#8480;</span>
    </span>
    <span class="copyright">
      &copy; {{ copyrightYear }} Nationwide Mutual Insurance Company
    </span>
  </footer>
</div>
