import { legacyAccountNavigationProvider } from "./account/navigation/account-navigation-router.service";
import { legacyAccountRetrieveProvider } from "./account/navigation/account-retrieve.service";
import { legacySpotlightProvider } from "./policy/modules/validation/services/policy-validation-navigation.service";
import { legacyAutoNavigationProvider } from "./policy/products/auto/navigation/auto-navigation-router.service";
import { legacyBusinessownersNavigationProvider } from "./policy/products/bop/navigation/bop-navigation-router.service";
import { legacyGeneralLiabilityNavigationProvider } from "./policy/products/general-liability/navigation/general-liability-navigation-router.service";
import { legacyInlandMarineNavigationProvider } from "./policy/products/inland-marine/navigation/inland-marine-navigation-router.service";
import { legacyPropertyNavigationProvider } from "./policy/products/property/navigation/property-navigation-router.service";
import { legacyUmbrellaNavigationProvider } from "./policy/products/umbrella/navigation/umbrella-navigation-router.service";
import { legacyWorkersCompensationNavigationProvider } from "./policy/products/workers-compensation/navigation/workers-compensation-navigation-router.service";
import { legacyReviewNavigationProvider } from "./review/navigation/review-navigation-router.service";

export function provideLegacyProviders() {
  return [
    legacyAccountNavigationProvider,
    legacyAccountRetrieveProvider,
    legacyAutoNavigationProvider,
    legacyBusinessownersNavigationProvider,
    legacyGeneralLiabilityNavigationProvider,
    legacyInlandMarineNavigationProvider,
    legacyPropertyNavigationProvider,
    legacySpotlightProvider,
    legacyReviewNavigationProvider,
    legacyUmbrellaNavigationProvider,
    legacyWorkersCompensationNavigationProvider,
  ];
}
