import {
  provideRouter,
  Routes,
  UrlHandlingStrategy,
  UrlTree,
} from "@angular/router";
import { userGuard } from "./core/user/guards/user.guard";
import { UnauthorizedComponent } from "./core/auth/components/unauthorized/unauthorized.component";
import { LogoutComponent } from "./core/auth/components/logout/logout.component";

const routes: Routes = [
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "upgrade",
    canActivate: [userGuard],
    children: [
      {
        path: "account",
        loadChildren: () =>
          import("./account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "policy-transaction",
        loadChildren: () =>
          import("./policy/policy.module").then((m) => m.PolicyModule),
      },
      {
        path: "mockup",
        loadChildren: () =>
          import("./mockup/mockup.module").then((m) => m.MockupModule),
      },
      {
        path: "review",
        loadChildren: () =>
          import("./review/review.module").then((m) => m.ReviewModule),
      },
    ],
  },
];

class UpgradeUrlHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree): boolean {
    return (
      url.toString().startsWith("/upgrade") ||
      url.toString().startsWith("/unauthorized") ||
      url.toString().startsWith("/logout")
    );
  }
  extract(url: UrlTree): UrlTree {
    return url;
  }
  merge(newUrlPart: UrlTree): UrlTree {
    return newUrlPart;
  }
}

export function provideAppRouting() {
  return [
    provideRouter(routes),
    { provide: UrlHandlingStrategy, useClass: UpgradeUrlHandlingStrategy },
  ];
}
