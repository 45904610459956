import { Component, computed, inject } from "@angular/core";
import { UserStoreService } from "@cq/app/core/user/stores/user-store.service";
import { IconModule } from "@cq/app/shared/icon/icon.module";

@Component({
  selector: "cq-unauthorized",
  standalone: true,
  imports: [IconModule],
  templateUrl: "./unauthorized.component.html",
  styleUrl: "./unauthorized.component.scss",
})
export class UnauthorizedComponent {
  #user = inject(UserStoreService);

  copyrightYear = new Date().getFullYear();
  helpDeskNumber = computed(() => this.#user.current().helpDeskNumber);
}
