import { DestroyRef, Injectable, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { ProgressService } from "../../progress/services/progress.service";
import { debounceTime, filter, Subject } from "rxjs";

class Navigation {
  readonly completed$ = new Subject<boolean>();

  constructor(public readonly id: string) {}

  complete() {
    this.completed$.next(true);
    this.completed$.complete();
  }
}

function legacy(id: number) {
  return `legacy:${id}`;
}

function upgrade(id: number) {
  return `upgrade:${id}`;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private readonly navigationEvents = new Map<string, Navigation>();

  private readonly router = inject(Router);
  private readonly progress = inject(ProgressService);

  private readonly routerEvents$ =
    this.router.events.pipe(takeUntilDestroyed());

  #destroyed = inject(DestroyRef);
  #hasCompleted = new Subject<boolean>();
  #hasLegacyCompleted = signal(false);
  readonly hasCompleted = signal(false);
  readonly navigationCompleted$ = new Subject<boolean>();

  // bypass validation
  #isBypassingValidation = signal(false);
  isBypassingValidation = this.#isBypassingValidation.asReadonly();

  constructor() {
    /*
      Due to competing routing systems across the Angular and AngularJS ecosystem we
      need to consider the staggered nature of routing events in order to allow for
      the current hybridized state of the system. The most stable variant I've been
      able to devise thus far is awaiting a period of navigation completions after
      at least a single legacy navigation event has completed.
    */
    this.#hasCompleted
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        debounceTime(50),
        filter(
          () => this.#hasLegacyCompleted() && this.navigationEvents.size === 0,
        ),
      )
      .subscribe(() => {
        this.hasCompleted.set(true);
        this.navigationCompleted$.next(true);
      });
  }

  // Navigation Monitoring
  monitor() {
    this.routerEvents$
      .pipe(takeUntilDestroyed(this.#destroyed))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.startNavigation(upgrade(event.id));
        }

        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.completeNavigation(upgrade(event.id));
        }
      });
  }

  start(id: number) {
    this.startNavigation(legacy(id));
  }

  complete(id: number) {
    this.#hasLegacyCompleted.set(true);
    this.completeNavigation(legacy(id));
  }

  private startNavigation(id: string) {
    const navigation = new Navigation(id);
    this.navigationEvents.set(navigation.id, navigation);
    this.progress.track(navigation.completed$).subscribe();
  }

  private completeNavigation(id: string) {
    const navigation = this.navigationEvents.get(id);
    navigation?.complete();
    this.navigationEvents.delete(id);
    this.#hasCompleted.next(true);
  }

  // Global Navigation Options

  /*
    Perform the provided navigation while bypassing any validation exit criteria that may be invoked throughout its lifecycle.

    This is a band-aid implementation in order to attempt to bridge together necessary functionality across two competing routing
    systems, and should be reexamined once the project is standardized on the Angular router.

    Attempts to heavily leverage this approach are likely to have unintended side effects, as the implementation is really only
    supportive of simple navigation events triggered from previously validated policies. As the state is shared across the entire
    application, and not simply the active navigation, it will certainly fail use cases which attempt to nest its usage.
  */
  bypassValidation<T>(navigation: () => Promise<T>) {
    this.#isBypassingValidation.set(true);
    return navigation().finally(() => this.#isBypassingValidation.set(false));
  }
}
