import { TransitionService } from "@uirouter/core";
import Kefir, { Observable, Pool } from "kefir";
import { CollapsibleContainerController } from "@clearquote/ui/lib/collapsible-container/collapsible-container.directive";

export interface Spotlight {
  id: string;
  info: {
    name: string;
    illuminate: string;
    notificationType: string;
    description: string;
    params: object;
    isAnInputTypeElement: boolean;
  };
  element: HTMLElement;
}

export interface LayoutShift {
  shiftType?: string;
  scrollElement: HTMLElement;
  height: number;
  index?: number;
  context?: CollapsibleContainerController;
}

export interface ShowActivationGroup {
  tooltipElement: HTMLElement;
}

export abstract class LegacySpotlightService {
  abstract focus: string | undefined;
  abstract focusInfo: unknown | undefined;
  abstract illuminate(id: string, data: any): void;
  abstract fade(): void;
}

export default class SpotlightService extends LegacySpotlightService {
  private _focus?: string;
  focusInfo: any;
  spotLightLog$: Observable<Spotlight[], unknown>;
  spotLight: Spotlight | null = null;
  spotLight$: Pool<Spotlight, unknown>;
  spotLightTooltip?: HTMLElement | null;
  spotLightElements = new Set<HTMLElement>();
  staticShowActivationGroup$: Pool<ShowActivationGroup, unknown>;
  staticShowActivationGroupLog$: Observable<ShowActivationGroup[], unknown>;
  layoutShifts$: Pool<LayoutShift, unknown>;
  layoutShiftsLog$: Observable<LayoutShift[], unknown>;
  noLayoutShift$: Pool<LayoutShift, unknown>;
  spotLightElementsEventsRemoval = new Map();
  currentActiveSpotLightElement: HTMLElement | null = null;
  spotLightElementForCurrentVisibleSpotlightTooltip: HTMLElement | null = null;
  boundEl?: JQLite;
  activeSpotLightCollapsibleContainer: CollapsibleContainerController | null =
    null;

  /* @ngInject */
  constructor($transitions: TransitionService) {
    super();

    this.spotLight$ = Kefir.pool<Spotlight, unknown>();

    this.staticShowActivationGroup$ = Kefir.pool();
    this.layoutShifts$ = Kefir.pool();
    this.noLayoutShift$ = Kefir.pool();

    this.staticShowActivationGroupLog$ =
      this.staticShowActivationGroup$.slidingWindow(1, 1);

    this.layoutShiftsLog$ = this.layoutShifts$.slidingWindow(2, 1);

    this.spotLightLog$ = this.spotLight$.slidingWindow(1, 1);

    this.spotLight$.onValue((spotLight) => {
      this.spotLight = spotLight;
    });

    this.spotLightLog$.onValue(() => {});
    this.staticShowActivationGroup$.onValue(() => {});

    this.noLayoutShift$.onValue(() => {});

    // Putting listener here because the spotlight directive appears
    // to fire two times for some reason?

    // Intended for use primarily when switching between collapsible
    // contains as that would cause a lot of the vertical height changes
    // which affects the scrolling to the spotlight element
    this.layoutShiftsLog$.onValue(() => {});

    $transitions.onExit({ to: "**" }, () => {
      // Container groups do not persists across pages and records
      // will need to be cleared
      this.activeSpotLightCollapsibleContainer = null;
    });
  }

  illuminate(id: string, data: any) {
    this._focus = id;
    this.focusInfo = data;
  }

  fade() {
    this._focus = undefined;
  }

  get focus() {
    return this._focus;
  }

  set focus(focus) {
    this._focus = focus;
  }

  get isSearching() {
    return this._focus !== undefined;
  }

  getBoundElement() {
    return this.boundEl ? this.boundEl[0] : undefined;
  }
}
