import { NgModule } from "@angular/core";
import { IconFontComponent } from "./components/icon-font/icon-font.component";
import { IconSvgComponent } from "./components/icon-svg/icon-svg.component";
import { IconSymbolComponent } from "./components/icon-symbol/icon-symbol.component";

const exposed = [IconFontComponent, IconSvgComponent, IconSymbolComponent];

@NgModule({
  imports: exposed,
  exports: exposed,
})
export class IconModule {}
