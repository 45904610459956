import { Injectable, inject } from "@angular/core";
import { AuthService } from "../../auth/services/auth.service";
import { WindowService } from "./window.service";
import { EventLogService } from "@cq/app/diagnostic";
import { ApplicationExitEvent } from "../../diagnostic/event";
import { Router } from "@angular/router";
import { NavigationService } from "../../navigation/services/navigation.service";

@Injectable({
  providedIn: "root",
})
export class SystemService {
  #auth = inject(AuthService);
  #event = inject(EventLogService);
  #navigation = inject(NavigationService);
  #router = inject(Router);
  #window = inject(WindowService);

  /** Log out of the application and then close the window or navigate to the logout screen */
  exit() {
    this.#event.log(new ApplicationExitEvent());
    this.#auth.logoff();
    this.#window.close();

    return this.#navigation.bypassValidation(() =>
      this.#router.navigate(["logout"], {
        info: { allowDeactivation: true },
      }),
    );
  }
}
