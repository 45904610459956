import { provideAuth, StsConfigLoader } from "angular-auth-oidc-client";
import { AuthConfigService } from "./services/auth-config.service";

export function provideAuthConfig() {
  return [
    AuthConfigService,
    provideAuth({
      loader: {
        provide: StsConfigLoader,
        useFactory: (authConfigService: AuthConfigService) =>
          authConfigService.createConfigLoader(),
        deps: [AuthConfigService],
      },
    }),
  ];
}
