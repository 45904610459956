import { Component } from "@angular/core";
import { IconModule } from "@cq/app/shared/icon/icon.module";

@Component({
  selector: "cq-warning-message",
  imports: [IconModule],
  templateUrl: "./warning-message.component.html",
  styleUrl: "./warning-message.component.scss",
})
export class WarningMessageComponent {}
