import { Directive, Input } from "@angular/core";

@Directive({
  selector: "cq-dialog-icon,[cq-dialog-icon]",
  standalone: true,
})
export class DialogIconDirective {
  @Input() name!: string;
  @Input() type?: "font" | "svg";
}
