import { Component, Pipe, PipeTransform } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import {
  Page,
  PageUpgradeService,
  PageUpgradeStatus,
} from "@cq/app/core/environment/services/page-upgrade.service";
import { ButtonModule } from "@cq/app/shared/button/button.module";
import { DialogModule } from "@cq/app/shared/dialog/dialog.module";
import { NoResultDialog } from "@cq/app/shared/dialog/model/dialog";
import { IconModule } from "@cq/app/shared/icon/icon.module";

interface PageMeta {
  page: Page;
  title: string;
  prefix: string;
}

const PAGES: PageMeta[] = [
  {
    page: Page.ACCOUNT_ADD_PRODUCTS,
    title: "Account Add Products",
    prefix: "account-add-products",
  },
  {
    page: Page.ACCOUNT_INFORMATION,
    title: "Account Information",
    prefix: "account-information",
  },
  {
    page: Page.ACCOUNT_QUESTIONS,
    title: "Account Questions",
    prefix: "account-questions",
  },
  {
    page: Page.UMBRELLA_INFORMATION,
    title: "Umbrella Information",
    prefix: "umbrella-information",
  },
];

class PageUpgradeConfiguration {
  configured = this.status.configured;
  overridden = this.status.isOverridden;
  page = this.meta.page;
  title = this.meta.title;
  prefix = this.meta.prefix;
  user = this.status.user;

  constructor(
    private readonly meta: PageMeta,
    private readonly status: PageUpgradeStatus,
  ) {}

  get current() {
    return this.overridden ? this.user : this.configured;
  }
}

@Pipe({
  name: "overrideId",
  standalone: true,
})
export class OverrideIdPipe implements PipeTransform {
  transform(prefix: string) {
    return `cq-pud-${prefix}-override`;
  }
}

@Pipe({
  name: "userId",
  standalone: true,
})
export class UserIdPipe implements PipeTransform {
  transform(prefix: string) {
    return `cq-pud-${prefix}-user`;
  }
}

@Component({
  selector: "cq-page-upgrade-dialog",
  imports: [
    ButtonModule,
    DialogModule,
    FormsModule,
    IconModule,
    MatTableModule,
    MatSlideToggleModule,
    OverrideIdPipe,
    UserIdPipe,
  ],
  templateUrl: "./page-upgrade-dialog.component.html",
  styleUrl: "./page-upgrade-dialog.component.scss",
})
export class PageUpgradeDialogComponent extends NoResultDialog<PageUpgradeDialogComponent> {
  upgradeColumns = ["title", "configured", "override", "user", "current"];
  upgradeDataSource = PAGES.map(
    (meta) =>
      new PageUpgradeConfiguration(meta, this.pageUpgrade.getStatus(meta.page)),
  );

  constructor(private readonly pageUpgrade: PageUpgradeService) {
    super();
  }

  cancel() {
    this.dialog.close();
  }

  save() {
    for (const config of this.upgradeDataSource) {
      if (config.overridden) {
        this.pageUpgrade.override(config.page, config.user ?? false);
      } else {
        this.pageUpgrade.reset(config.page);
      }
    }
    this.dialog.close();
  }
}
