import { inject, Injectable } from "@angular/core";
import { PolicyTransaction } from "@cq/app/policy/models/policy";
import { Validation } from "@cq/app/policy/models/validation";
import { PolicyTransactionStoreService } from "@cq/app/policy/services/policy-transaction-store.service";
import { UmbrellaNavigationRouterService } from "../navigation/umbrella-navigation-router.service";
import {
  AUTO,
  BUSINESSOWNERS,
  GENERAL_LIABILITY,
} from "@cq/app/core/model/product";
import { BopNavigationRouterService } from "../../bop/navigation/bop-navigation-router.service";
import { GeneralLiabilityNavigationRouterService } from "../../general-liability/navigation/general-liability-navigation-router.service";
import { LegacyValidationNavigationStrategy } from "@cq/app/policy/modules/validation/models/validation-strategy";
import { AutoNavigationRouterService } from "../../auto/navigation/auto-navigation-router.service";

const SUPPORTED_PRODUCTS = [AUTO, BUSINESSOWNERS, GENERAL_LIABILITY];

@Injectable()
export class UnderlyingCoverageLimitStrategyService
  implements LegacyValidationNavigationStrategy
{
  readonly #policyTransactionStore = inject(PolicyTransactionStoreService);

  // routers
  readonly #autoNavigationRouter = inject(AutoNavigationRouterService);
  readonly #bopNavigationRouter = inject(BopNavigationRouterService);
  readonly #generalLiabilityNavigationRouter = inject(
    GeneralLiabilityNavigationRouterService,
  );
  readonly #umbrellaNavigationRouter = inject(UmbrellaNavigationRouterService);

  supports(validation: Validation): boolean {
    return validation.id === "CU_UnderlyingCoverages_LiabilityLimits";
  }

  navigate(
    transaction: PolicyTransaction,
    validation: Validation,
  ): Promise<boolean> {
    const firstAssociatedTransaction =
      this.getAssociatedPolicyTransaction(validation);

    // navigate to the associated transaction product specific page
    if (firstAssociatedTransaction) {
      switch (firstAssociatedTransaction.product) {
        case AUTO:
          return this.#autoNavigationRouter.navigateToAutoInformation(
            firstAssociatedTransaction.id,
          );
        case BUSINESSOWNERS:
          return this.#bopNavigationRouter.navigateToBusinessownersCoverages(
            firstAssociatedTransaction.id,
          );
        case GENERAL_LIABILITY:
          return this.#generalLiabilityNavigationRouter.navigateToGeneralLiabilityInformation(
            firstAssociatedTransaction.id,
          );
      }
    }

    // fallback, navigate to Umbrella Information for the requested transaction
    return this.#umbrellaNavigationRouter.navigateToUmbrellaInformation(
      transaction.id,
    );
  }

  illuminate(validation: Validation): string | undefined {
    const firstAssociatedTransaction =
      this.getAssociatedPolicyTransaction(validation);

    if (firstAssociatedTransaction) {
      switch (firstAssociatedTransaction.product) {
        case AUTO:
          return "auto-liability-limits";
        case BUSINESSOWNERS:
          return "BP7BusinessLiability";
        case GENERAL_LIABILITY:
          return "policyInfo";
      }
    }

    return undefined;
  }

  private getAssociatedPolicyTransaction(validation: Validation) {
    const policyNumbers = validation.policyNumbers ?? [];
    const transactions = this.#policyTransactionStore.transactions();
    const associatedTransactions = transactions.filter(
      (transaction) =>
        SUPPORTED_PRODUCTS.includes(transaction.product) &&
        policyNumbers.includes(transaction.policyNumber),
    );
    return associatedTransactions.at(0);
  }
}
