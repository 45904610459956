import { ConfigService } from "@cq/app/core/environment/services/config.service";

export enum Features {
  TEST_FEATURE = "x-test-feature-do-not-use", // Enum for testing only, I don't like it, but alternatives aren't great.
  FEATURE_ENABLE_CP_PEAK_SEASON = "enable_cp_peak_season",
  ENABLE_HIDE_DEFAULT_PRODUCER = "enable_hide_default_producer",
  ENABLE_ARTEMIS = "enable_artemis",
  ENABLE_DSE_IRPM_RANGE_ADJUSTMENT = "enable_dse_irpm_range_adjustment",
  ENABLE_CP7_BLANKETID_VBRS = "enable_cp7_blanketid_vbrs",
  FEATURE_DEFAULT_CARWASH_COVERAGE = "enable_default_carwash_coverage",
  FEATURE_ENABLE_MULTI_STATE_FLOOD_BLANKET = "enable_multi_state_flood_blanket",
  ENABLE_COMPOSITE_PRINT = "enable_composite_print",
  ENFORCE_ACCOUNT_INFORMATION_UPGRADE = "enforce_account_information_upgrade",
  ENFORCE_ACCOUNT_ADD_PRODUCT_UPGRADE = "enforce_account_add_product_upgrade",
  BYPASS_LOB_VBR_NAVIGATION = "bypass_lob_vbr_navigation",
}

export class Feature {
  private readonly features = new Map<string, boolean>();

  constructor() {}

  initialize(AEAppConfigService: ConfigService) {
    for (const feature of Object.values(Features)) {
      this.features.set(feature, AEAppConfigService.enabled(feature));
    }
  }

  isEnabled(feature: Features): boolean {
    return this.features.get(feature) || false;
  }
}

export const FEATURE = new Feature();
