import { computed, inject, Injectable, signal } from "@angular/core";
import { PolicyTransactionStoreService } from "../services/policy-transaction-store.service";
import { PolicyTransaction } from "../models/policy";
import { PolicyPeriodStoreService } from "./policy-period-store.service";
import { PolicyValidationStoreService } from "./policy-validation-store.service";

@Injectable({
  providedIn: "root",
})
export class ActivePolicyStoreService {
  #periodStore = inject(PolicyPeriodStoreService);
  #transactionStore = inject(PolicyTransactionStoreService);
  #validationStore = inject(PolicyValidationStoreService);

  // active policy transaction id
  #policyTransactionId = signal<string | undefined>(undefined);
  policyTransactionId = this.#policyTransactionId.asReadonly();

  // active policy transaction
  policyTransaction = computed(() => {
    const transactionId = this.#policyTransactionId();
    return this.#transactionStore
      .history()
      .find((transaction) => transaction.id === transactionId);
  });

  // active policy period
  policyPeriod = computed(() => {
    const transactionId = this.#policyTransactionId();
    return this.#periodStore
      .periods()
      .find((period) => period.policyTransactionId === transactionId);
  });

  // validations for the active policy transaction
  validations = computed(() => {
    const transaction = this.policyTransaction();
    return this.#validationStore.getPolicyValidations(transaction);
  });

  // store the transaction as the currently active policy transaction
  activate(transaction: PolicyTransaction) {
    this.#transactionStore.record(transaction); // ensure the transaction is within the transaction history
    this.setActivePolicyTransactionId(transaction.id); // establish the active transaction id
  }

  // remove the transaction as the currently active policy transaction
  deactivate(transaction: PolicyTransaction) {
    this.clearActivePolicyTransactionId(transaction.id);
  }

  // set the active policy transaction id
  setActivePolicyTransactionId(policyTransactionId: string) {
    this.#policyTransactionId.set(policyTransactionId);
  }

  // clear the active policy transaction id
  clearActivePolicyTransactionId(policyTransactionId: string) {
    const current = this.#policyTransactionId();
    if (current && current === policyTransactionId) {
      this.#policyTransactionId.set(undefined);
    }
  }
}
