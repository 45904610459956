import { Component } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ToolsModule } from "./tools/tools.module";
import { NotificationOverlayComponent } from "./core/notification/components/notification-overlay/notification-overlay.component";

@Component({
  selector: "cq-root",
  imports: [NotificationOverlayComponent, ToolsModule],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "clearquote";

  constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry.registerFontClassAlias(
      "default",
      "material-icons mat-ligature-font",
    );

    matIconRegistry.registerFontClassAlias(
      "outlined",
      "material-icons-outlined mat-ligature-font",
    );

    matIconRegistry.registerFontClassAlias(
      "symbols-outlined",
      "material-symbols-outlined mat-ligature-font",
    );

    matIconRegistry.addSvgIconResolver((name) => {
      return sanitizer.bypassSecurityTrustResourceUrl(
        `assets/icons/${name}.svg`,
      );
    });
  }
}
