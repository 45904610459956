<div class="auth-container">
  <div class="header">
    <img src="assets/images/nationwide-white-logo.svg" alt="Nationwide" />
  </div>
  <div class="body">
    <div class="circle">
      <cq-icon-symbol
        class="message-icon"
        name="check_circle"
        [weight]="200"
      ></cq-icon-symbol>
    </div>
    <div class="message">Logout Successful</div>
    <div class="description">
      You have been successfully logged out of ClearQuote. To continue your
      session, please return to your previous application and relaunch
      ClearQuote.
    </div>
  </div>
  <footer>
    <span class="brand">
      ClearQuote <span class="service-mark">&#8480;</span>
    </span>
    <span class="copyright">
      &copy; {{ copyrightYear }} Nationwide Mutual Insurance Company
    </span>
  </footer>
</div>
