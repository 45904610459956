import {
  EnvironmentInjector,
  inject,
  Injectable,
  Injector,
  Type,
} from "@angular/core";
import { ValidationNavigationStrategy } from "../models/validation-strategy";
import { Product } from "@cq/app/core/model/product";
import { Validation } from "@cq/app/policy/models/validation";
import { PRODUCT_VALIDATION_STRATEGIES } from "@cq/app/policy/products";

@Injectable({
  providedIn: "root",
})
export class ValidationNavigationRegistryService {
  #injector = inject(EnvironmentInjector);
  #products = inject(PRODUCT_VALIDATION_STRATEGIES);
  #registry = new Map<Product, ValidationNavigationStrategy[]>();

  constructor() {
    this.#products.forEach((strategy) =>
      this.register(strategy.product, strategy.strategies),
    );
  }

  register<T extends ValidationNavigationStrategy>(
    product: Product,
    strategyTypes: Type<T>[],
  ) {
    const injector = Injector.create({
      providers: strategyTypes,
      parent: this.#injector,
    });
    const strategies = strategyTypes.map((strategyType) =>
      injector.get(strategyType),
    );
    const existing = this.#registry.get(product) ?? [];
    this.#registry.set(product, [...existing, ...strategies]);
  }

  get(product: Product, validation: Validation) {
    const strategies = this.#registry.get(product) ?? [];
    return strategies.find((strategy) => strategy.supports(validation));
  }
}
