import { CommonModule } from "@angular/common";
import { Component, TemplateRef } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ButtonModule } from "@cq/app/shared/button/button.module";
import { DialogModule } from "../../dialog.module";
import { RequiredDataDialog } from "../../model/dialog";

export enum ConfirmResult {
  CANCEL,
  CONFIRM,
}

export interface ConfirmDialogData {
  message?: string;
  messageTemplate?: TemplateRef<unknown>;
  messageContext?: unknown;
}

@Component({
  selector: "cq-confirm-dialog",
  imports: [CommonModule, DialogModule, MatIconModule, ButtonModule],
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent extends RequiredDataDialog<
  ConfirmDialogComponent,
  ConfirmResult,
  ConfirmDialogData
> {
  confirm() {
    this.dialog.close(ConfirmResult.CONFIRM);
  }

  cancel() {
    this.dialog.close(ConfirmResult.CANCEL);
  }
}
