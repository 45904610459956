import { Component, computed, inject } from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { NotificationComponent } from "../notification/notification.component";
import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "cq-notification-overlay",
  imports: [NotificationComponent],
  templateUrl: "./notification-overlay.component.html",
  styleUrl: "./notification-overlay.component.scss",
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(75%)" }),
        animate("250ms", style({ opacity: 1, transform: "translateX(0)" })),
      ]),
      transition(":leave", [
        style({
          opacity: 1,
          height: "*",
          "pointer-events": "none",
          transform: "translateY(0)",
        }),
        sequence([
          animate(
            "250ms",
            style({ opacity: 0, transform: "translateY(-50%)" }),
          ),
          animate("500ms", style({ height: 0 })),
        ]),
      ]),
    ]),
  ],
})
export class NotificationOverlayComponent {
  private notification = inject(NotificationService);
  notifications = computed(() => this.notification.notifications().slice(0, 5));
}
