import { Component, ElementRef, HostBinding, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TooltipModule } from "@cq/app/shared/tooltip/tooltip.module";
import { fromEvent } from "rxjs";

type Appearance = "standard" | "tight";

@Component({
  selector: "cq-button",
  imports: [MatButtonModule, MatIconModule, TooltipModule],
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() label: string | undefined;
  @Input() appearance: Appearance = "standard";
  @Input() @HostBinding("class.disabled") disabled: boolean | null = false;

  constructor(elementRef: ElementRef) {
    // Prevent click events from propagating if the button is disabled
    fromEvent<MouseEvent>(elementRef.nativeElement, "click", {
      capture: true,
    }).subscribe((e) => {
      if (this.disabled) {
        e.stopImmediatePropagation();
      }
    });
  }
}
