import { CommonModule } from "@angular/common";
import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "cq-dialog-actions",
  imports: [CommonModule],
  templateUrl: "./dialog-actions.component.html",
  styleUrls: ["./dialog-actions.component.scss"],
})
export class DialogActionsComponent {
  @HostBinding("class.cq-dialog-actions") private readonly baseClass = true;
}
