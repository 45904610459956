import { inject, Injectable, signal } from "@angular/core";
import { Validation } from "../../../models/validation";
import { PolicyTransaction } from "../../../models/policy";
import { PolicyTransactionStoreService } from "../../../services/policy-transaction-store.service";
import { NavigationService } from "@cq/app/core/navigation/services/navigation.service";
import { ValidationNavigationRegistryService } from "./validation-navigation-registry.service";
import {
  LegacyValidationNavigationStrategy,
  ValidationNavigationStrategy,
} from "../models/validation-strategy";
import { LegacySpotlightService } from "@legacy/ui/lib/navigation/spotlight.service";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";

export function legacySpotlightFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacySpotlightService>("cquiSpotlightService");
}

export const legacySpotlightProvider = {
  provide: LegacySpotlightService,
  useFactory: legacySpotlightFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class PolicyValidationNavigationService {
  #navigation = inject(NavigationService);
  #registry = inject(ValidationNavigationRegistryService);
  #spotlight = inject(LegacySpotlightService);
  #transactionStore = inject(PolicyTransactionStoreService);

  #activeValidation = signal<Validation | undefined>(undefined);
  activeValidation = this.#activeValidation.asReadonly();
  activeValidation$ = toObservable(this.#activeValidation);

  constructor() {
    /*
      Clear active validations after navigation.

      It is assumed that this cleanup attempt will introduce issues as our
      page and validation scenario complexity increases. There is likely no
      perfect solution to attempting to tidy up after improperly configured
      validation navigation, however the naive implementation should suffice
      until a more robust solution is required.
    */
    this.#navigation.navigationCompleted$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.#activeValidation.set(undefined));
  }

  supports(
    transaction: PolicyTransaction | string,
    validation: Validation,
  ): boolean {
    // lookup associated transaction information
    const tx = this.getTransaction(transaction);

    // if no transaction is found then navigation is unsupported
    if (!tx) {
      return false;
    }

    // check if a registered strategy supports the validation
    return this.#registry.get(tx.product, validation) !== undefined;
  }

  navigate(
    transaction: PolicyTransaction | string,
    validation: Validation,
  ): Promise<boolean> {
    // lookup associated transaction information
    const tx = this.getTransaction(transaction);

    // if no transaction is found, do not attempt navigation
    if (!tx) {
      return Promise.resolve(false);
    }

    // if navigation is not supported, do not attempt navigation
    const strategy = this.#registry.get(tx.product, validation);
    if (!strategy) {
      return Promise.resolve(false);
    }

    // store active validation
    this.#activeValidation.set(validation);

    if (this.isLegacySupported(strategy)) {
      const illuminate = strategy.illuminate(validation);
      if (illuminate) {
        this.#spotlight.illuminate(illuminate, {
          description: validation.message,
          notificationType: validation.severity,
          noHighlight: true,
        });
      }
    }

    // navigate bypassing validation
    return this.#navigation.bypassValidation(() =>
      strategy.navigate(tx, validation),
    );
  }

  activate(validation: Validation) {
    this.#activeValidation.set(validation);
  }

  clear(validation?: Validation) {
    const active = this.#activeValidation();
    if (!validation || active === validation) {
      this.#activeValidation.set(undefined);
    }
  }

  private getTransaction(transaction: PolicyTransaction | string) {
    return typeof transaction === "string"
      ? this.#transactionStore.transactions().find((t) => t.id === transaction)
      : transaction;
  }

  private isLegacySupported(
    strategy: ValidationNavigationStrategy,
  ): strategy is LegacyValidationNavigationStrategy {
    return "illuminate" in strategy;
  }
}
