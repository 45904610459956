import { inject, Injectable } from "@angular/core";
import { CriticalEvent, EventLogService } from "@cq/app/diagnostic";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { NEVER, of, switchMap, take } from "rxjs";
import { Identity } from "../models/identity";
import { IdentityStoreService } from "./identity-store.service";
import { WindowService } from "../../system/services/window.service";
import { DOCUMENT } from "@angular/common";

function navigateToStoredEndpoint(window: WindowService) {
  const href = sessionStorage.getItem("cq.redirect");

  if (href != null) {
    sessionStorage.removeItem("cq.redirect");
    window.replace(href);
    return true;
  }

  return false;
}

function storeEndpoint(document: Document) {
  sessionStorage.setItem("cq.redirect", document.location.href);
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  #document = inject(DOCUMENT);
  #event = inject(EventLogService);
  #identity = inject(IdentityStoreService);
  #oidc = inject(OidcSecurityService);
  #window = inject(WindowService);

  login() {
    return this.#oidc.checkAuth().pipe(
      // Not sure if this is necessary, the documentation doesn't indicate if this subscription completes immediately
      take(1),
      // handle authorization response
      switchMap((response) => {
        // check for authentication errors
        if (response.errorMessage) {
          // log the authentication error
          this.#event.log(
            new CriticalEvent("auth-error", response.errorMessage, {
              message: response.errorMessage,
            }),
          );
          return of(undefined);
        }

        // initiate authentication flow
        if (!response.isAuthenticated) {
          storeEndpoint(this.#document); // store the requested url
          this.#oidc.authorize(); // trigger authentication
          return NEVER; // identity will be established after the authentication callback
        }

        // redirect to the stored url if one has been set
        if (navigateToStoredEndpoint(this.#window)) {
          return NEVER; // identity will be established after the page reload
        }

        // Establish user identity
        const identity = new Identity(response.userData);
        this.#identity.set(identity);
        return of(identity);
      }),
    );
  }

  logoff() {
    this.#oidc.logoffLocal();
  }

  get accessToken$() {
    return this.#oidc.getAccessToken();
  }

  get identityToken$() {
    return this.#oidc.getIdToken();
  }
}
