import { Injectable, computed, signal } from "@angular/core";
import {
  patchPolicyTransaction,
  PolicyGroup,
  PolicyTransaction,
  PolicyTransactionPatch,
} from "../models/policy";
import { uniq } from "lodash";

@Injectable({
  providedIn: "root",
})
export class PolicyTransactionStoreService {
  #history = signal<PolicyTransaction[]>([]); // complete transaction history
  #transactions = signal<PolicyTransaction[]>([]); // transactions loaded into the system

  // transaction history filtered to retained transactions
  #maintained = computed(() =>
    this.#history().filter((transactions) => !transactions.isDiscarded),
  );

  // policy groups
  #groups = computed(() => {
    const maintained = this.#maintained();
    const groups = maintained
      .map((transaction) => transaction.group)
      .filter((group): group is PolicyGroup => group !== undefined);
    // determine unique group numbers
    const groupNumbers = uniq(groups.map((group) => group.number));
    // map group information for each unique group number
    return groupNumbers
      .map((groupNumber) =>
        groups.find((group) => group.number === groupNumber),
      )
      .filter((group): group is PolicyGroup => group !== undefined);
  });

  // latest policy group by expiration date
  latestPolicyGroup = computed(() => {
    const groups = this.#groups();
    if (!groups.length) {
      return undefined;
    }
    // sort descending by expiration date
    const sorted = [...groups].sort(
      (p1, p2) => p2.expiration.toMillis() - p1.expiration.toMillis(),
    );
    // result in latest policy group by expiration date
    return sorted[0];
  });

  // bound transactions within the latest policy group
  readonly boundTransactions = computed(() => {
    const transactions = this.#history();
    const latestPolicyGroup = this.latestPolicyGroup();
    return transactions.filter(
      (transaction) =>
        transaction.group &&
        transaction.group.number === latestPolicyGroup?.number &&
        ["submission", "renewal"].includes(transaction.type) &&
        transaction.isBound,
    );
  });

  public readonly history = this.#history.asReadonly();
  public readonly transactions = this.#transactions.asReadonly();
  public readonly hasTransactions = computed(
    () => this.transactions().length > 0,
  );
  public readonly hasBoundTransaction = computed(
    () =>
      this.#history().filter((transaction) => transaction.isBound).length > 0,
  );

  // Policy products added to account
  public readonly products = computed(() =>
    this.transactions().map((transaction) => transaction.product),
  );

  setPolicyTransactionHistory(transactions: PolicyTransaction[]) {
    this.#history.set(transactions);
  }

  setPolicyTransactions(transactions: PolicyTransaction[]) {
    this.#transactions.set(transactions);
  }

  addPolicyTransactions(transactions: PolicyTransaction[]) {
    this.#history.update((current) => [...current, ...transactions]);
    this.#transactions.update((current) => [...current, ...transactions]);
  }

  update(policyTransactionId: string, patch: PolicyTransactionPatch) {
    // find the existing transaction record
    const record = this.#history().find((t) => t.id === policyTransactionId);
    const hasTransactionLoaded = this.#transactions().some(
      (transaction) => transaction.id === policyTransactionId,
    );

    if (!record) {
      // bail out if no record is found
      return undefined;
    }

    // merge the provided update patch
    const updated = patchPolicyTransaction(record, patch);

    // update the transaction record
    this.#history.update((current) => {
      const filtered = current.filter(
        (transaction) => transaction.id !== policyTransactionId,
      );
      return [...filtered, updated];
    });

    if (hasTransactionLoaded) {
      // update the loaded transactions
      this.#transactions.update((current) => {
        const filtered = current.filter(
          (transaction) => transaction.id !== policyTransactionId,
        );
        return [...filtered, updated];
      });
    }

    // result in the updated transaction
    return updated;
  }

  // ensure the transaction is within the transaction history
  record(transaction: PolicyTransaction) {
    const record = this.#history().find((t) => t.id === transaction.id);
    if (!record) {
      this.#history.update((current) => [...current, transaction]);
    }
  }
}
